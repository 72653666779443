<template>
  <div>
     <div class="container" style="padding: 1rem">
        <div class="row">
           <div class="d-flex">
              <div class="mx-auto d-flex">
                 <img :src="require('../assets/img/new/ghs-01.png')" alt="Logo Here" style="width: 100px">
                 <h1 class="text-secondary-orange" style="padding-top: 5pt;padding-left: 7pt;text-indent: 0pt;text-align: left;">Privacy Policy</h1>
              </div>
           </div>
           <div class="col-md-12">
              <h3 class="text-secondary-orange" style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Privacy Policy</h3>

              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">At Gear Health Systems, we are committed to protecting your privacy and we take great care with your Personal Data.</p>
              
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">This privacy policy explains what Personal Data we collect from you, what we use that Personal Data for, with whom the Personal Data is shared and how it is protected and stored. It also sets out your rights and who you can contact for Personal Data or queries.</p>
              
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="mailto:info@gearhealthsystem.com" class="s1" target="_blank">If you have any questions, feel free to contact us at </a><a href="mailto:info@gearhealthsystem.com" class="a" target="_blank">info@gearhealthsystem.com</a><a href="mailto:info@gearhealthsystem.com" class="s1" target="_blank">.</a></p>
              
              <p style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">This privacy policy should be read together with our Terms and Conditions, which together apply to your use of the Gear Health Systems website, platform and applications.</p>
              
              <h3 class="text-secondary-orange" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Kenya Data Protection Act 2019</h3>
              
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Under the above Act, we are a Data Controller of your information.</p>
              
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Gear Health Systems’ legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
              
              <p style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 149%;text-align: left;">Gear Health Systems needs to perform a contract with you You have given Gear Health Systems permission to do so</p>
              
              <p style="padding-left: 5pt;text-indent: 0pt;line-height: 149%;text-align: left;">Processing your personal information is in Gear Health Systems legitimate interests Gear Health Systems needs to comply with the law</p>
              
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Gear Health Systems will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
              
              <div class="alert alert-info" role="alert">
                <h6 style="padding-left: 5pt;" class="fw-bold">
                <font-awesome-icon :icon="['fa', 'info-circle']"></font-awesome-icon> To Note
                </h6>
                <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify; margin-bottom: 0px">

                  If you are a resident of Kenya, you have certain data protection rights. If you wish to be informed what Personal Information, we hold about you and if you want it to be removed from our systems, please contact us at
                  
                  <a href="mailto:info@gearhealthsystem.com" target="_blank">info@gearhealthsystem.com</a>
                </p>
              </div>
              
              <p style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">In certain circumstances, you have the following data protection rights:</p>
              
              <ul id="l1">
                 <li data-list-text="">
                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 13pt;text-align: left;">The right to access, update or to delete the information we have on you.</p>
                 </li>
                 <li data-list-text="">
                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 13pt;text-align: left;">The right of rectification.</p>
                 </li>
                 <li data-list-text="">
                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 13pt;text-align: left;">The right to object.</p>
                 </li>
                 <li data-list-text="">
                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 13pt;text-align: left;">The right of restriction.</p>
                 </li>
                 <li data-list-text="">
                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 13pt;text-align: left;">The right to data portability</p>
                 </li>
                 <li data-list-text="">
                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 13pt;text-align: left;">The right to withdraw consent</p>
                 </li>
              </ul>
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">By accessing or using the Gear Health Systems Website, Application and associated services, you agree to the practices and policies outlined in this privacy policy and you hereby consent to the collection, use, and sharing of your Personal Data as described in this privacy policy</p>
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Personal Data” means Personal Data about a natural person who is identified or can be identified from that Personal Data (either by itself or when combined with other Personal Data).</p>
              <ol id="l2">
                 <li data-list-text="1.">
                    <h3 class="text-secondary-orange" style="padding-left: 16pt;text-indent: -11pt;line-height: 13pt;text-align: left;">What Personal Data do we collect?</h3>
                    <h6 class="text-dark fw-light fst-italic" style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left; margin-top: 1rem">Online and Offline Payment Processing Information</h6>

                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">When you make a payment through our services, your payment card Personal Data is collected and stored by our payment processing partner. Our payment processing partner collects your voluntarily provided payment card Personal Data necessary to process your payment. Such partner’s use and storage of Personal Data is governed by its applicable terms of service and privacy policy.</p>

                    <h6 class="text-dark fw-light fst-italic" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Information from Third-Party Sources</h6>

                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Some third parties, such as our business partners and service providers, provide us with Personal Data about you. If you interact with a third-party service when using our services, such as if you use a third-party service to log-in to our services (e.g., Facebook or Google), or if you share content from our services through a third party social media service, the third-party service will send us certain Personal Data about you if the third-party service and your account settings allow such sharing. The Personal Data we receive will depend on the policies and your account settings with the third-party service. You understand the Personal Data transmitted to us is covered by this Privacy Policy; for example, the basic Personal Data we receive from Google if you choose to sign up with your Google account.</p>
                    
                    <h6 class="text-dark fw-light fst-italic" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Information Gear Health Systems Automatically Collects When You Use The services</h6>

                    <p class="text-muted fst-italic" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Gear Health System and all its subsidiaries</p>
                    
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Some Personal Data, which may include Personal Data, is automatically collected when you use our services, such as traffic data. We also may automatically collect certain data when you use the services, such as (1) IP address; (2) domain server; (3) type of device(s) used to access the services; (4) web browser(s) used to access the services; (5) referring webpage or other source through which you accessed the services; (6) geolocation Personal Data; and(7) other statistics and Personal Data associated with the interaction between your browser or device and the services.</p>
                 </li>
                 <li data-list-text="2.">
                    <h3 class="text-secondary-orange" style="padding-left: 16pt;text-indent: -11pt;text-align: left;">How Gear Health Systems Collects Information</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Gear Health Systems collects Personal Data (including Personal Data and traffic data) when you use and interact with the services, and in some cases from third party sources. Such means of collection include:</p>
                    <ul id="l3">
                       <li data-list-text="">
                          <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">When you use the services’ interactive tools and services, such as searching for Health Products and Services and searching for available appointments with Healthcare Services prior to appointments;</p>
                       </li>
                       <li data-list-text="">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">When you voluntarily provide Personal Data in free-form text boxes through the services or through responses to surveys;</p>
                       </li>
                       <li data-list-text="">
                          <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">If you download and install certain applications and software we make available, we may receive and collect Personal Data transmitted from your computing device for the purpose of providing you the relevant services;</p>
                       </li>
                       <li data-list-text="">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">If you use a location-enabled browser or download our mobile application, we may receive Personal Data about your location and mobile device, as applicable;</p>
                       </li>
                       <li data-list-text="">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">Through cookies, analytics services and other tracking technology, as</p>
                       </li>
                       <li data-list-text="">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">When you use the “Contact Us” function on the Site, send us an email or otherwise contact us.</p>
                       </li>
                    </ul>
                 </li>
                 <li data-list-text="3.">
                    <h3 class="text-secondary-orange" style="padding-top: 3pt;padding-left: 16pt;text-indent: -11pt;text-align: left;">For what purpose do we collect the Personal Data?</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">We collect this data to improve services for the user and have better experience.</p>
                 </li>
                 <li data-list-text="4.">
                    <h3 class="text-secondary-orange" style="padding-left: 16pt;text-indent: -11pt;text-align: left;">Tracking Tools</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We may use tools outlined below in order to provide our services to, advertise to, and to better understand users.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Cookies: “Cookies” are small computer files transferred to your computing device that contain Personal Data such as user ID, user preferences, lists of pages visited and activities conducted while using the services. We use cookies to improve or tailor the services, customize advertisements by tracking navigation habits, measuring performance, and storing authentication status so re-entering credentials may not be required, customize user experiences with the services, and for analytics and fraud prevention.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Web Analytics services: We may use third-party analytics services in connection with our services, including, for example, to collect your country where you are logging on, IP address, time spent on the Website or mobile applications, pages visited and other user Personal Data.</p>
                 </li>
                 <li data-list-text="5.">
                    <h3 class="text-secondary-orange" style="padding-left: 16pt;text-indent: -11pt;text-align: left;">Who do we share this Personal Data with?</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">We may disclose and transfer your Personal Data in connection with such partners’ use of the services</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">In certain circumstances, and in order to perform the services, we may share certain Personal Data that we collect from you to select third parties, agents, contractors and service providers or partners with restrictions that they may not use your Personal Data for any other purposes and on the basis of a Non-Disclosure agreement and subject to compliance with Data Protection Laws, as described in this section:</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Healthcare Providers: We may share your Personal Data with Healthcare Providers with whom you choose to schedule through the services.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We will never sell email addresses to third parties. We may share your Personal Data with our partners to customize or display our advertising.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We may share your Personal Data and/or traffic data with our partners who perform operational services (such as hosting, billing, fulfilment, data storage, security, insurance verification, web service analytics, or ad serving) and/or who make certain services, features or functionality available to our users.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We may share your Personal Data with the insurance provider you identify to us (and do so via our partners) to determine eligibility or otherwise obtain benefit plans and other insurance-related Personal Data on your behalf.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Public Information and Submissions: You agree that any Personal Data that you may reveal in a review posting, online discussion or forum is intended for the public and is not in any way private. Carefully consider whether to disclose any Personal Data in any public posting or forum. Your submissions may be seen and/or collected by third parties and may be used by others in ways we are unable to control or predict.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Protection of Gear Health Systems and Others: We also may need to disclose your Personal Data or any other Personal Data we collect about you if we determine in good faith that such disclosure is needed to: (1) comply with or fulfil our obligations under applicable law, regulation, court order or other legal process; (2) protect the rights, property or safety of you, Gear Health Systems or another party; (3) enforce the Agreement or other agreements with you; or (4) respond to claims that any posting or other content violates third-party rights.</p>
                 </li>
                 <li data-list-text="6.">
                    <h3 class="text-secondary-orange" style="padding-left: 16pt;text-indent: -11pt;text-align: left;">International Data Transfers</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">From time to time, Gear Health Systems may need to transfer your Personal Data outside of the country or may receive Personal Data from outside of the country as part of its service delivery. Both the receiving and disclosing parties shall ensure adequate safeguards with respect to security and protection of Personal Data are in place in compliance to Data Protection laws.</p>
                 </li>
                 <li data-list-text="7.">
                    <h3 class="text-secondary-orange" style="padding-left: 16pt;text-indent: -11pt;text-align: left;">How secure is your Personal Data?</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We are committed to protecting the security of your Data. We use a variety of security technologies and procedures to help protect your Data from unauthorised access and use.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">For orders specifically through our site and app’s we secure the data on GDPR compliant</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">AWS servers hosted in the European Union.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We have implemented strict internal guidelines to ensure that your privacy is safeguarded at every level of our organisation. We will continue to update policies and implement additional security features as new technologies become available.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We endeavour to follow generally accepted industry standards to protect the Personal Data submitted to us, both during transmission and in storage as follows;</p>
                    <ol id="l4">
                       <li data-list-text="a.">
                          <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">A firewall to prevent unauthorized external access to our system.</p>
                       </li>
                       <li data-list-text="b.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">Your account or profileis protected by a password for your privacy and security. If you access your account via a third-party site or service, you may have additional or different sign-on protections via that third-party site or service. You must prevent unauthorized access to your account and Personal Data by selecting and protecting your password and/or other sign-on mechanism appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account.</p>
                       </li>
                       <li data-list-text="c.">
                          <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">SSL certificates for customer-facing websites and external connections, except connections that go through a secured VPN.</p>
                       </li>
                       <li data-list-text="d.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Our systems have capability to detect any data security breaches and timely appropriate action taken to remedy.</p>
                       </li>
                       <li data-list-text="e.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">All our employees are trained to ensure high standards in relation to data protection.</p>
                       </li>
                       <li data-list-text="f.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">We use best efforts to keep confidential any Personal Data collected which may be of confidential nature, with the exception of such Personal Data which:</p>
                          <ul id="l5">
                             <li data-list-text="o">
                                <p style="padding-top: 3pt;padding-left: 77pt;text-indent: -18pt;text-align: left;">was already known to us prior to receiving such Personal Data from the end user.</p>
                             </li>
                             <li data-list-text="o">
                                <p style="padding-top: 3pt;padding-left: 77pt;text-indent: -18pt;text-align: left;">was received from a third party who is not subject to similar confidentiality restrictions.</p>
                             </li>
                             <li data-list-text="o">
                                <p style="padding-top: 3pt;padding-left: 77pt;text-indent: -18pt;text-align: left;">is independently developed by us; and/or</p>
                             </li>
                             <li data-list-text="o">
                                <p style="padding-top: 3pt;padding-left: 77pt;text-indent: -18pt;text-align: left;">is required to be disclosed by applicable Law.</p>
                                <p style="padding-top: 3pt;padding-left: 41pt;text-indent: 0pt;text-align: left;">Notwithstanding clause above, we reserve the right to disclose any Personal Data collected to any person if, in our reasonable opinion, it is:</p>
                             </li>
                             <li data-list-text="o">
                                <p style="padding-left: 77pt;text-indent: -18pt;text-align: left;">required by applicable Law.</p>
                             </li>
                             <li data-list-text="o">
                                <p style="padding-top: 3pt;padding-left: 77pt;text-indent: -18pt;text-align: left;">necessary to comply with a court order or other legal process.</p>
                                <p style="padding-top: 3pt;padding-left: 41pt;text-indent: 0pt;text-align: left;">We shall take all reasonable precautions to preserve the integrity and prevent any corruption or loss, damage or destruction of Personal Data in our possession</p>
                             </li>
                          </ul>
                       </li>
                       <li data-list-text="g.">
                          <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">Our security practices and procedures are certified and are audited on a regular basis.</p>
                       </li>
                       <li data-list-text="h.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">Although we make best possible efforts to transmit and store all the Personal Data in a secure operating environment that is not open to the public, there is no such thing as complete security, and we do not guarantee that there will be no unintended disclosures of any Information Collected. If We become aware that any Personal Data has been disclosed in a manner that is not in accordance with this Policy, We will use best efforts to notify you of the nature and extent of such disclosure (to the extent of our knowledge) as soon as reasonably possible and as permitted by Law.</p>
                       </li>
                       <li data-list-text="i.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">We store and process your Personal Data on our servers in Amazon Web Services. We maintain industry standard backup and archival systems.</p>
                       </li>
                       <li data-list-text="j.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">Although we make good faith efforts to store Personal Data in a secure operating environment that is not open to the public, we do not and cannot guarantee the security of your Personal Data. If at any time during or after our relationship we believe that the security of your Personal Data may have been compromised, we may seek to notify you of that development. If a notification is appropriate, we will endeavour to notify you as promptly as possible under the circumstances. If we have your e-mail address, we may notify you by e-mail to the most recent e-mail address you have provided us in your account profile. Please keep your e-mail address in your account up to date which can be updated at any time in your account profile.</p>
                       </li>
                    </ol>
                 </li>
                 <li data-list-text="8.">
                    <h3 class="text-secondary-orange" style="padding-top: 3pt;padding-left: 16pt;text-indent: -11pt;text-align: left;">What Choices Do You Have When Using The Service</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">You can always opt not to disclose Personal Data to us, but keep in mind some Personal Data may be needed to register with us or to take advantage of some of our features.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">If you are a registered user of the services, you can modify certain Personal Data or account Personal Data by logging in and accessing your account. The Personal Data you can view, update, and delete may change as the services change.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="mailto:info@gearhealthsystem.com" class="s1" target="_blank">If you wish to close your account, please email us at </a><span style=" color: #0462C1; font-family:&quot;Microsoft Sans Serif&quot;, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">info@gearhealthsystem.com</span>. Gear Health Systems will use reasonable efforts to promptly delete your account and the related Personal Data. Please note, however, that Gear Health Systems reserves the right to retain Personal Data from closed accounts, including to comply with law, prevent fraud, resolve disputes, enforce the agreement and take other actions permitted by law.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">You must promptly notify us if any of your account data is lost, stolen or used without permission.</p>
                 </li>
                 <li data-list-text="9.">
                    <h3 class="text-secondary-orange" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">For how long do we keep your Personal Data? Where do we keep it? How do we keep it?</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you services. In some cases, we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, prevent fraud, enforce the Agreement, or as otherwise permitted or required by applicable law, rule or regulation. Afterwards, we retain some Personal Data in a depersonalized or aggregated form but not in a way that would identify you personally.</p>
                 </li>
                 <li data-list-text="10.">
                    <h3 class="text-secondary-orange" style="padding-left: 22pt;text-indent: -17pt;text-align: left;">Information Provided on Behalf of Minors and Others</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">As noted in the Terms of Use, the services are not intended for use by children and children under the age of 18 should not use our service. Gear Health Systems does not knowingly collect any Personal Data from minors, nor is the services directed to or attended for use by children. If you are under 18, please do not attempt to register for the services or send any Personal Data about yourself to us.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">If you are a parent or legal guardian of a minor, you may, in compliance with the Agreement, use the services on behalf of such minor. Any Personal Data that you provide us while using the services on behalf of your minor child will be treated as Personal Data as otherwise provided herein.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">If you use the services on behalf of another person, regardless of age, you agree that Gear Health Systems may contact you for any communication made in connection with providing the services or any legally required communications. You further agree to forward or share any such communication with any person on behalf of whom you are using the services.</p>
                 </li>
                 <li data-list-text="11.">
                    <h3 class="text-secondary-orange" style="padding-left: 22pt;text-indent: -17pt;text-align: left;">Other Web services</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The services contain links to or embedded content from third party web services. A link to or embedded content from a non-Gear Health Systems web service does not mean that we endorse that service, the quality or accuracy of Personal Data presented on the non-Gear Health Systems service or the persons or entities associated with the non-Gear Health Systems service. If you decide to visit a third-party web service, you are subject to the privacy policy and Terms of Use of the third-party web service as applicable and we are not responsible for the policies and practices of the third-party web service. We encourage you to ask questions before you disclose your Personal Data to others.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">You may have arrived at the services from, or begun your use of the services at, a third-party web service, including a third-party web service that links to Gear Health Systems or embeds Gear Health Systems content. The presence of such links or content on third-party web services does not mean that we</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">GEAR HEALTH SYSTEMS</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">endorse that web service and applications, the quality or accuracy of Personal Data presented on the non-Gear Health Systems web service or the persons or entities associated with the non-Gear Health Systems web service. You may be subject to the privacy policy of the third-party web service as applicable and we are not responsible for the policies and practices of the third-party web services. In addition, the policies and practices of third parties do not apply to your Personal Data, including Personal Data, obtained pursuant to this Privacy Policy.</p>
                 </li>
                 <li data-list-text="12.">
                    <h3 class="text-secondary-orange" style="padding-left: 22pt;text-indent: -17pt;text-align: left;">Healthcare Service Providers and Their Obligations</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Gear Health Systems will share basic Personal Data on the user with the Healthcare Providers who you elect to visit for an appointment through the Service. This ensures that an appointment can be booked at your preferred time and date. These Healthcare Service Providers may need to collect additional Personal Data from you to build a medical history while carrying out their duties.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The Healthcare Provider is required to obtain consent from the patient in person during their initial consultation, to allow the Healthcare Provider to collect and store Personal Data, medical history and other Information as needed, on the patient. The Healthcare Provider will not share this Personal Data with us or anyone else without the prior approval of the patient. Gear Health Systems is in no way responsible for obtaining this approval for the Healthcare Provider and is in no way liable for any data collected by the Healthcare Provider.</p>
                 </li>
                 <li data-list-text="13.">
                    <h3 class="text-secondary-orange" style="padding-left: 22pt;text-indent: -17pt;text-align: left;">What are your rights as a data subject?</h3>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We have mechanisms in place to ensure that you can exercise the following legal rights.</p>
                    <ol id="l6">
                       <li data-list-text="a.">
                          <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to give and withdraw consent at any time</p>
                       </li>
                       <li data-list-text="b.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to be informed of the use to which Personal Data is to be put</p>
                       </li>
                       <li data-list-text="c.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to access your Personal Data</p>
                       </li>
                       <li data-list-text="d.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to object to the processing of all or part of your Personal Data unless there is a compelling legitimate interest or for legal purposes</p>
                       </li>
                       <li data-list-text="e.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to request confirmation of what Personal Data is held</p>
                       </li>
                       <li data-list-text="f.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: justify;">Right to request correction of any errors, omissions, out-dated, Personal Data inaccurate, incomplete or misleading personal data, and request third parties processing the personal data of the request. This will however not apply to Personal Data required for legal purposes</p>
                       </li>
                       <li data-list-text="g.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to request cessation of use the Personal Data</p>
                       </li>
                       <li data-list-text="h.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to request removal from any contact/mailing list</p>
                       </li>
                       <li data-list-text="i.">
                          <p style="padding-top: 4pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to restrict use of your Personal Data</p>
                       </li>
                       <li data-list-text="j.">
                          <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to request deletion of Personal Data that is irrelevant, excessive or obtained unlawfully, and request third parties processing the Personal Data of the request.</p>
                          <p style="padding-left: 41pt;text-indent: 0pt;text-align: left;">This will however not apply to Personal Data required for legal purposes</p>
                       </li>
                       <li data-list-text="k.">
                          <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;text-align: left;">Right to request transfer of Personal Data to another organisation</p>
                       </li>
                    </ol>
                 </li>
                 <li data-list-text="14.">
                    <h3 class="text-secondary-orange" style="padding-top: 3pt;padding-left: 22pt;text-indent: -17pt;text-align: left;">Review of Policy</h3>
                 </li>
              </ol>
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The effective date of this Privacy Policy is set forth at the top of this webpage. We will notify you of any material change by posting notice on this webpage. Your continued use of the services after the effective date constitutes your acceptance of the amended Privacy Policy. We encourage you to periodically review this page for the latest Personal Data on our privacy practices. Any amended Privacy Policy supersedes all previous versions. If you do not agree to future changes to this privacy policy, you must stop using the services after the effective date of such changes</p>
              <h3 class="text-secondary-orange" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Consent statement</h3>
              <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">By using our platforms and applications you consent to have read and understood, and agree to Gear Health Systems&#39; privacy policy, and applicable terms and conditions.</p>

              <div class="alert alert-info" role="alert">
                <h6 style="padding-left: 5pt;" class="fw-bold">
                <font-awesome-icon :icon="['fa', 'info-circle']"></font-awesome-icon> To Note
                </h6>
                <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify; margin-bottom: 0px">

                  You are also aware that you can withdraw your consent at any time by sending an email to 
                  
                  <a href="mailto:info@gearhealthsystem.com" target="_blank">info@gearhealthsystem.com</a>
                </p>
              </div>
           </div>
        </div>
     </div>
     <main-footer v-if="footer" :page="'Home'" v-model:prop_data="footer"></main-footer>
  </div>
</template>

<script>
  import MainFooter from '../components/Footer'
  export default {
    data() {
      return {
        footer: {
          "title": "About Gear Health Systems.",
          "address": "",
          "phone_number": "+254 (0)713 152 100",
          "email": "info@gearhealthsystem.com",
          "background_color": "dark",
          "description": "Gear Health Systems is Disrupting Access Barriers to Quality Healthcare in Africa",
          "call_to_action": {
            "text": "Contact us",
            "background_color": "secondary-orange",
            "color": "white",
            "link": "#contacts"
          },
          navigation: [
            {
              "text": "Team",
              "link": "#team",
              "type": "id"
            },
            {
              "text": "The Problem",
              "link": "#problem",
              "type": "id"
            },
            {
              "text": "Services",
              "link": "#services",
              "type": "id"
            },
            {
              "text": "Partners",
              "link": "#partners",
              "type": "id"
            },
            {
              "text": "Contacts",
              "link": "#contacts",
              "type": "id"
            },
            {
              "text": "Terms of Service",
              "link": "tos",
              "type": "route"
            }   ,         
            {
              "text": "Privacy Policy",
              "link": "privacy",
              "type": "route"
            }   
          ],
          socials: [
            {
              "social": "twitter",
              "link": "https://www.twitter.com/@GearHealthSys",
              "background_color": "info"
            },
            {
              "social": "linkedin",
              "link": "https://www.linkedin.com/company/gear-health-systems",
              "background_color": "primary"
            },
            {
              "social": "instagram",
              "link": "http://www.instagram.com/gearhealthsys",
              "background_color": "burgundy"
            },
            {
              "social": "youtube",
              "link": "https://www.youtube.com/channel/UCQjUg2zZ-jx1KfQMcDkmr7w",
              "background_color": "danger"
            },
            {
              "social": "facebook",
              "link": "https://www.facebook.com/Gear-Health-Systems-102109359062443",
              "background_color": "primary"
            }                                     
          ],          
        },
      }
    },
    components: {
      MainFooter
    }, 
  }
</script>